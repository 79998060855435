import { Liquid } from 'liquidjs';

export class TemplateService {
    static Init() {
        this.engine = this.GetEngine();
    }

    static GetEngine() {
        this.engine = new Liquid({
            root: '/assets/js/dist/templates',
            extname: '.html'          // extension used for layouts/includes (.html) templates
        });

        return this.engine;
    }

    static async GetRenderedTemplate(templateName, data) {

        if (!this.engine) {
            this.Init();
        }

        return await this.engine.renderFile(`${templateName}?v=${build}`, data);
    }

    static GetRenderedTemplateAsync(templateName, data) {

        if (!this.engine) {
            this.Init();
        }

        return this.engine.renderFile(`${templateName}?v=${build}`, data);
    }

    static GetRenderedTemplateSync(templateName, data) {
        if (!this.engine) {
            this.Init();
        }

        DEBUG && console.info("====> Rendering Template - " + templateName);

        return this.engine.renderFileSync(`${templateName}?v=${build}`, data);
    }

    static RenderTemplateElement(templateName, data, element) {
        if (!this.engine) {
            this.Init();
        }

        return this.engine.renderFile(`${templateName}?v=${build}`, data)
            .then(content => {
                element.innerHTML = content;
            });
    }
}