import { Notyf } from 'notyf';
import { ConfigService } from './ConfigService'

export class UtilService {

    // ********************************************
    // GetOS function
    // Returns the device OS
    // ********************************************
    static getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac';
        }
        else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iPhone/iPod';
        }
        else if (/iPad/.test(userAgent)) {
            os = 'iPad';
        }
        else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        }
        else if (/Android/.test(userAgent)) {
            os = 'Android';
        }
        else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }
        else {
            os = 'Other';
        }

        return os;
    }


    // ********************************************
    // Function to Get ClientID from Cookie
    // ********************************************
    static getClientID() {
        var attrs = this.getCookie(this.ccmClientCookie);

        if (attrs) {
            var values = attrs.split("&");
            var obj = {};
            for (var i = 0; i < values.length; i++) {
                var keyValue = values[i].split("=");
                obj[keyValue[0]] = keyValue[1];
            }

            return obj["clientId"];
        }

        var clientId = this.guid();
        var lastClientDate = new Date();

        this.setCookie(ConfigService.ccmClientCookie(), `clientID=${clientId}&lastClientDate=${lastClientDate}`, 3650);

        return clientId;
    }


    // ********************************************
    // Generic Cookie Functions
    // ********************************************
    static setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    static getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    static eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    // ********************************************
    // Generic GUID Functions
    // ********************************************
    static guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }


    // ********************************************
    // Campus Functions
    // ********************************************
    static setCampus(campus) {
        this.setCookie(ConfigService.cookieCampus(), campus, 365);
    }

    static clearCampus() {
        this.eraseCookie(ConfigService.cookieCampus());
    }

    static getCampus() {
        try {

            var homeCookie = this.getCookie(ConfigService.cookieCampus());
            if (homeCookie != 'clearhome') {
                return homeCookie;
            }
        } catch (error) {
            return null;
        }
    }

    // ********************************************
    // Function to Add Media Analytics
    // ********************************************
    static async addMediaAnalytics(entity, entityData, campus, isMobile) {
        var clientType = `Web`;

        if (isMobile) {
            clientType = 'Mobile';
        }

        var os = this.getOS();
        var clientID = this.getClientID();

        if (campus === '5') {
            campus = 'melbourne';
        }

        if (campus === '6') {
            campus = 'viera';
        }

        if (campus === '7') {
            campus = 'sebastian';
        }

        if (campus === '15') {
            campus = 'espanol';
        }


        var url = `https://ccmanalytics.azurewebsites.net/api/CreateSermonUsageData?code=sPSae5Gb0af4ZvnAmQkDoRidX5T4aWqbaIrouTe1gVTcKbK3ynjNIA==&ClientID=${clientID}&Entity=${entity}&EntityData=${entityData}&clientType=${clientType}&os=${os}&campus=${campus}`;

        try {
            fetch(`${url}`, {
                headers: {
                    "Content-Type": "text/plain"
                },

            });
        }
        catch (err) {
            console.error('==> Analytics::Analytics failed to initialize.');
            console.error(err);
        }


        // $.getJSON(url);

        // No Geolocation for Mobile Pages
        //if (window.location.href.indexOf("mobilep") === -1) {
        //    if ("geolocation" in navigator) { //check geolocation available 
        //        //try to get user current location using getCurrentPosition() method
        //        navigator.geolocation.getCurrentPosition(function (position) {
        //            console.log("Found your location \nLat : " + position.coords.latitude + " \nLang :" + position.coords.longitude);
        //        });
        //    } else {
        //        console.log("Browser doesn't support geolocation!");
        //    }
        //}
    }


    // ********************************************
    // Function to dynamically load CSS
    // ********************************************
    static loadExternalCSS(url) {
        // Check if the CSS file with the given URL is already loaded
        if (!this.isCSSLoaded(url)) {
            // Create a new link element for the CSS file
            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = url;

            // Append the link element to the head of the document
            document.head.appendChild(link);
        }
    }

    static isCSSLoaded(url) {
        // Check if a CSS file with the given URL is already loaded
        var links = document.getElementsByTagName('link');
        for (var i = 0; i < links.length; i++) {
            if (links[i].href === url) {
                return true;
            }
        }
        return false;
    }

    // ********************************************
    // CopyUrlFunctions
    // ********************************************
    static createCopyUrl(queryVar, queryVal) {
        let currentPageUrl = window.location.href;

        // Prevent doubling querystring
        if (currentPageUrl.includes('?')) {
            currentPageUrl = currentPageUrl.split('?')[0];
        }

        return `${currentPageUrl}?${queryVar}=${queryVal}`;
    }

    static addCopyUrl(element, queryVar, queryVal) {
        let self = this;
        let copyUrl = this.createCopyUrl(queryVar, queryVal);

        // Clone the element to remove any existing events
        let elementId = element.id;

        self.cloneElement(element.id);

        let newElement = document.getElementById(elementId);

        newElement.addEventListener('click', () => {
            navigator.clipboard.writeText(copyUrl)
            .then(function () {
                // Show success message
                self.showSuccessToast('Url copied to clipboard', 3000);
            }).catch(function (error) {
                // Show error message
                self.showErrorToast('Something went wrong', 4000);
            });
        });
    }
    

    // ********************************************
    // Contact Modal Functions
    // ********************************************
    static initContactModal() {
        const element = document.getElementById('modalContact');
        let that = this;

        element.addEventListener('show.bs.modal', (evt) => {
            DEBUG && console.log('===> Contact Modal Init');
            DEBUG && console.log(evt.relatedTarget);
            that.openContactModal(
                evt.relatedTarget.getAttribute('data-contactGUID'),
                evt.relatedTarget.getAttribute('data-subject'),
                evt.relatedTarget.getAttribute('data-name')
            );
        });

        // Wire Up Form Elements
        var contactForm = document.getElementById('formModalContact');

        contactForm.addEventListener('submit', function (event) {

            event.preventDefault();
            event.stopPropagation();

            // Check Validity
            if (!contactForm.checkValidity()) {
                console.info("*** => Contact Form INVALID");
            }
            else {
                console.info("*** => Contact Form Valid / Submitting");

                // Get Captcha Token
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LflXjcpAAAAALr80wVEcMlED_FNjFM4_UP3oa9J', { action: 'submit' })
                        .then(function (token) {
                            console.log("Captcha Output");
                            console.log(token);

                            // Submit Form via AJAX!!
                            that.sendContactModal(token);
                        });
                });
            }

            contactForm.classList.add('was-validated')
        }, false)     
    }

    static openContactModal(contactGuid, subjectText, person) {
        DEBUG && console.log('===> Contact Modal Opened');

        // Gather Elements
        const modalContactTitle = document.getElementById('modalContactTitle');

        const hiddenContactGUID = document.getElementById('contactGUID');
        hiddenContactGUID.value = contactGuid;

        const subject = document.getElementById('Subject');
        subject.value = subjectText;

        // Replace Modal Header
        modalContactTitle.innerText = `Send Email to ${person}`;
    }

    static sendContactModal(token) {
        DEBUG && console.log('===> Send Contact Modal');
        var contactForm = document.getElementById('formModalContact');    
        let that = this;

        // Gather Form Data
        const formData = new FormData(contactForm);
        console.log(formData);

        // Create JSON Object
        const jsonObject = {};

        // Convert FormData to JSON object
        formData.forEach((value, key) => {
            jsonObject[key] = value;
        });

        // Add Calling URL
        jsonObject["Url"] = window.location.href;

        DEBUG && console.log('===> ContactForm Data');
        DEBUG && console.log(jsonObject);

        // Send the data to the API
        fetch(`${apiRoot}/api/Communication/SendContactModalEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Verification-Key': token
            },
            body: JSON.stringify(jsonObject)
        })

            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to submit form');
                }
            })

            .then((data) => {
                // Handle the API response data

                // Close Modal
                that.closeModal('modalContact');

                // Clear the form
                contactForm.reset();

                // Show Success Growler
                that.showSuccessToast('Thank You, Your Email was Sent', 7000, true);
            })

            .catch((error) => {
                console.error('Error:', error);
                // Show Error / Try Again
                that.showErrorToast('An error occurred while sending your message.', 20000, true);
            });

    }


    // ********************************************
    // Newsletter Functions
    // ********************************************
    static initNewsletterButton() {
        DEBUG && console.log('===> Newsletter Button Setup');

        // Local 
        let that = this;

        var elements = document.querySelectorAll('[data-component="newsletterButton"]');
        elements.forEach(element => {
            element.addEventListener("click", function () {
                that.openNewsletterSignUp(this);
            });
        });
    }

    static openNewsletterSignUp(buttonObj) {
        DEBUG && console.log('===> Newsletter Signup Opened');

        // Gather Button Configurations
        var publicationId = buttonObj.getAttribute('data-publicationID');
        var publicationTitle = buttonObj.getAttribute('data-title');

        // Local 
        let that = this;

        var elements = document.querySelectorAll('[data-component="Newsletter"]');
        elements.forEach(element => {
            var formElement = document.getElementById("formNewsletter");

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`Form Element:            ${formElement}`);
            DEBUG && console.info('**************************************************');

            // Update Fields / Labels
            var pubIdField = formElement.querySelector('input[name="Publication_ID"]');
            pubIdField.value = publicationId;

            var titleArea = document.getElementById('newsletterTitle');
            titleArea.textContent = publicationTitle;

            // Authenticated Check
            var userObj = that.getUserObj();

            if (userObj) {
                // Prepopulate Newsletter Fields
                var FirstNameField = formElement.querySelector('input[name="FirstName"]');
                FirstNameField.value = userObj.First_Name;

                var LastNameField = formElement.querySelector('input[name="LastName"]');
                LastNameField.value = userObj.Last_Name;

                var EmailAddressField = formElement.querySelector('input[name="EmailAddress"]');
                EmailAddressField.value = userObj.Email_Address;

                var UserIDField = formElement.querySelector('input[name="UserID"]');
                UserIDField.value = userObj.User_ID;
            }

            element.classList.add("show");

            // Wire up Cancel Button
            var cancelButton = document.getElementById('cancelFormNewsletter');

            cancelButton.addEventListener('click', function () {
                element.classList.remove("show");
            });

            formElement.addEventListener('submit', function (event) {

                event.preventDefault();
                event.stopPropagation();

                // Check Validity
                if (!formElement.checkValidity()) {
                    DEBUG && console.info("*** => Newletter Form INVALID");
                }
                else {
                    DEBUG && console.info("*** => Newletter Form Valid / Submitting");

                    // Get Captcha Token
                    grecaptcha.ready(function () {
                        grecaptcha.execute('6LflXjcpAAAAALr80wVEcMlED_FNjFM4_UP3oa9J', { action: 'submit' })
                            .then(function (token) {
                                DEBUG && console.log(token);

                                // Submit Form via AJAX!!
                                that.sendNewsletterForm(token, formElement, element);
                            });
                    });
                }

                formElement.classList.add('was-validated')
            }, false)                 
        });
    }

    static sendNewsletterForm(token, formElement, element) {
        DEBUG && console.log('===> Send Newsletter');

        let that = this;

        // Gather Form Data
        const formData = new FormData(formElement);

        DEBUG && console.log(formData);

        // Create JSON Object
        const jsonObject = {};

        // Convert FormData to JSON object
        formData.forEach((value, key) => {
            jsonObject[key] = value;
        });

        // Null Check on User_ID
        if (jsonObject.UserID == "") {
            jsonObject.UserID = null;
        }

        DEBUG && console.log(jsonObject);

        // Send the data to the API
        fetch(`${apiRoot}/api/Communication/NewsletterSignup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Verification-Key': token
            },
            body: JSON.stringify(jsonObject)
        })

            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to submit newsletter signup form');
                }
            })

            .then((data) => {
                // Handle the API response data
                DEBUG && console.log(data);

                // Hide the Newsletter
                element.classList.remove('show');

                // Clear the form
                formElement.reset();

                // Show Success Growler
                that.showSuccessToast('You are signed up for the newsletter', 7000, true);
            })

            .catch((error) => {
                console.error('Error:', error);
                // Show Error / Try Again
                that.showErrorToast('An error occurred while signing up', 20000, true);
            });
    }



    // ********************************************
    // User Functions
    // ********************************************
    static getUserObj() {
        // Retrieve session userObect
        var userObj = sessionStorage.getItem('userObj');

        if (userObj) {

            try {
                // Attempt to parse Session UserObject
                return JSON.parse(userObj);
            }
            catch (error) {
                console.error(error.message);
                return null;
            }
        }

        return null;
    }



    // ********************************************
    // Modal Functions
    // ********************************************
    static closeModal(elementId) {
        var myModalEl = document.getElementById(elementId);
        var modal = bootstrap.Modal.getInstance(myModalEl)
        modal.hide();
    }

    static openModal(elementId) {
        var myModalEl = document.getElementById(elementId);
        var modal = bootstrap.Modal.getOrCreateInstance(myModalEl)
        modal.show();
    }

    // ********************************************
    // Toast Functions
    // ********************************************
    static showSuccessToast(text, duration, allowDismiss) {
        DEBUG && console.info('===> Show Toast: Success');
        // Create an instance of Notyf
        const notyf = new Notyf();

        notyf.success({
            message: text,
            duration: duration,
            dismissible: allowDismiss,
            ripple: true,
            position: {
                x: 'right',
                y: 'top',
            }
        });
    }

    static showErrorToast(text, duration, allowDismiss) {
        DEBUG && console.info('===> Show Toast: Error');
        // Create an instance of Notyf
        const notyf = new Notyf();

        notyf.error({
            message: text,
            duration: duration,
            dismissible: allowDismiss,
            ripple: true,
            position: {
                x: 'right',
                y: 'top',
            }
        });
    }


    // ********************************************
    // Redirect To Authentication
    // ********************************************
    static redirectToAuthentication(redirectUrl) {
        var authUrl = sessionStorage.getItem("authUrl");

        if (redirectUrl) {
            var stateRedirect = encodeURIComponent(redirectUrl);
            window.location.href = `${authUrl}&state=${stateRedirect}`;
        }
        else {
            window.location.href = `${authUrl}&state=${encodeURIComponent(window.location.href)}`;
        }
    }


    // ********************************************
    // GetFormDataObject 
    // ********************************************
    static getFormDataObject(formElement) {
        DEBUG && console.log("------------------------------------------");
        DEBUG && console.log("===> Getting Form Data Obj");
        // Get Form Data
        const formData = new FormData(formElement);
        // Create JSON Object
        const jsonObject = {};

        // Convert FormData to JSON object
        formData.forEach((value, key) => {
            jsonObject[key] = value;
        });

        DEBUG && console.log(jsonObject);
        DEBUG && console.log("------------------------------------------");
        return jsonObject;
    }


    // ********************************************
    // Load Script External Script File
    // ********************************************
    static loadScript(url, callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.onload = callback;
        // Optional: callback to be executed once the script is loaded
        // Optional: make use of IFFE to immediately invoke

        document.head.appendChild(script);
    }

    // ********************************************
    // ShowLoadingModal
    // ********************************************
    static ShowLoadingModal() {
        const loadingElement = document.getElementById("loadingOverlay");
        loadingElement.style.display = "flex";
    }

    // ********************************************
    // HideLoadingModal
    // ********************************************
    static HideLoadingModal() {
        const loadingElement = document.getElementById("loadingOverlay");
        loadingElement.style.display = "none";
    }

    // ********************************************
    // ShowElement - Show Element by ID
    // ********************************************
    static ShowElement(elementId) {
        var obj = document.getElementById(`${elementId}`);
        obj.style.display = "block";
    }

    // ********************************************
    // HideElement - Hides Elements by ID
    // ********************************************
    static HideElement(elementId) {
        var obj = document.getElementById(`${elementId}`);
        obj.style.display = "none";
    }

    // ********************************************
    // CloneElement - Clone Element by ID
    // ********************************************
    static cloneElement(elementId) {
        const oldElement = document.getElementById(`${elementId}`);
        const newElement = oldElement.cloneNode(true);
        oldElement.parentNode.replaceChild(newElement, oldElement);
    }

    // ********************************************
    // localStorage Utilities
    // ********************************************
    static writeLocalStorageObject(key, obj) {
        localStorage.setItem(key, JSON.stringify(obj));
    }

    static writeLocalStorageValue(key, obj) {
        localStorage.setItem(key, obj);
    }

    static readLocalStorageValue(key) {
        return localStorage.getItem(key);
    }

    static readLocalStorageObject(key) {
        var obj = localStorage.getItem(key);

        if (obj) {
            return JSON.parse(obj);
        }
        else {
            return null;
        }
    }

    static checkLocalStorageItem(key) {
        return localStorage.getItem(key) !== null;
    }

    // ********************************************
    // base64 Utilities
    // ********************************************
    static base64Encode(s) {
        return btoa(s);
    }

    static base64Decode(encoded) {
        return atob(encoded);
    }

    static base64EncodeNumber(s) {
        s = s * 2;
        return btoa(s.toString());
    }

    static base64DecodeNumber(encoded) {
        let s = atob(encoded);
        let n = parseInt(s);
        return s / 2;
    }


    // ********************************************
    // Form Utilities
    // ********************************************
    static formSetup(formElement) {
        let phoneFields = formElement.querySelectorAll(`[data-type="phone"]`);
        phoneFields.forEach(phone => {
            phone.addEventListener('input', function (event) {
                const input = event.target;
                const inputValue = input.value.replace(/\D/g, ''); // Remove non-numeric characters

                // Limit the length of inputValue to 10
                if (inputValue.length > 10) {
                    inputValue = inputValue.substring(0, 10);
                }

                if (inputValue.length <= 10) {
                    // Format as xxx-xxx-xxxx
                    input.value = inputValue.replace(/(\d{3})(\d{0,3})(\d{0,4})/, function (_, firstPart, secondPart, thirdPart) {
                        let formattedNumber = '';

                        if (firstPart) formattedNumber += `${firstPart}`;
                        if (secondPart) formattedNumber += `-${secondPart}`;
                        if (thirdPart) formattedNumber += `-${thirdPart}`;

                        return formattedNumber;
                    });
                }
            });
        });
    }


    // ********************************************
    // DOM Utilities
    // ********************************************
    static findParentByClass(element, className) {
        while (element && !element.classList.contains(className)) {
            element = element.parentElement;
        }
        return element; 
    }
}