
export class ApiService {
    static async getData(endpoint) {
        try {
            const response = await fetch(`${apiRoot}/api/${endpoint}`);

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static getUrlData(url) {
        fetch(`${url}`)
            .then(response => response.json())
            .then(data => {
                return data;
            });            
    }

    static async getTextAsync(endpoint) {
        try {
            const response = await fetch(`${apiRoot}/api/${endpoint}`, {
                headers: {
                    "Content-Type": "text/plain"
                }
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.text();
            return data;
        }
        catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static getText(endpoint) {
        fetch(`${apiRoot}/${endpoint}`, {
            headers: {
                "Content-Type": "text/plain"
            }
        })
            .then(response => response.text())
            .then(text => {
                return text;
            })
            .catch(error => console.error(error));
    }

    static postData(endpoint, payload) {
        try {

            // Configure the request
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json" 
                },
                body: JSON.stringify(payload) // Convert data to JSON format
            };

            // Sending the POST request
            fetch(`${apiRoot}/${endpoint}`, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`);
                    }
                    response.json()
                })
                .then(obj => { return obj; });
        }
        catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    static postAuthenticatedData(endpoint, payload) {
        return new Promise(function (resolve, reject) {
            var authToken = localStorage.getItem("mpp-widgets_AuthToken");
            var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

            if (!authToken || !authExpiration) {
                return reject(null);
            }

            // Configure the Request
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                }                
            };

            if (payload) {
                requestOptions.body = JSON.stringify(payload);
            }

            // Sending the request
            fetch(`${apiRoot}/api/${endpoint}`, requestOptions)
                .then(response => response.json())
                .then(obj => {
                    DEBUG && console.info(obj);
                    return resolve(obj);
                })
                .catch(error => {
                    return reject(error);
                });
        });
    }

    static getAuthenticatedData(endpoint) {
        return new Promise(function (resolve, reject) {
            var authToken = localStorage.getItem("mpp-widgets_AuthToken");
            var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

            if (!authToken || !authExpiration) {
                return reject(null);
            }

            // Configure the Request
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                }
            };

            // Sending the request
            fetch(`${apiRoot}/api/${endpoint}`, requestOptions)
                .then(response => response.json())
                .then(obj => {
                    DEBUG && console.info(obj);
                    return resolve(obj);
                })
                .catch(error => {
                    return reject(error);
                });
        });

    }

    static async getAuthenticatedDataAsync(endpoint) {
        var authToken = localStorage.getItem("mpp-widgets_AuthToken");
        var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

        if (!authToken || !authExpiration) {
            return reject(null);
        }

        // Configure the Request
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        };

        try {
            const response = await fetch(`${apiRoot}/api/${endpoint}`, requestOptions);

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    

    static getApiData(endpoint) {
        return new Promise(function (resolve, reject) {
            var authToken = localStorage.getItem("mpp-widgets_AuthToken");
            var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

            // Configure the Request
            var requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            if (authToken && authExpiration && authToken != "null") {
                requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${authToken}`
                    }
                };                
            }

            // Sending the request
            fetch(`${apiRoot}/api/${endpoint}`, requestOptions)
                .then(response => response.json())
                .then(obj => {
                    DEBUG && console.info(obj);
                    return resolve(obj);
                })
                .catch(error => {
                    return reject(error);
                });
        });

    }

    static postApiData(endpoint, payload, token, isFormData, isMultiPart) {
        return new Promise(function (resolve, reject) {
            var authToken = localStorage.getItem("mpp-widgets_AuthToken");
            var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

            var auth = false;

            if (authToken && authExpiration && authToken != "null") {
                auth = true;
            }

            // Configure the Request
            var requestOptions = {
                method: "POST",
                headers: {
                }
            };

            if (auth) {
                requestOptions.headers["Authorization"] = `Bearer ${authToken}`;
            }

            if (token) {
                requestOptions.headers["Verification-Key"] = token;
            }
            
            if (payload && isFormData) {
                requestOptions.body = payload;
            }
            else if (payload) {
                requestOptions.body = JSON.stringify(payload);
            }

            if (isMultiPart) {
                // This is AutoDetected
                // requestOptions.headers["Content-Type"] = "multipart/form-data";
            }
            else {
                requestOptions.headers["Content-Type"] = "application/json";
            }

            // Sending the request
            fetch(`${apiRoot}/api/${endpoint}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        console.error(response);
                        return reject(response);
                    }
                    response.json();
                })
                .then(obj => {
                    return resolve(obj);
                })
                .catch(error => {
                    return reject(error);
                });
        });
    }


    // ********************************************
    // Function Get API Data
    // ********************************************   
    static async getApiDataAsync(endpoint, requireAuth) {
        var authObj = this.getAuth(requireAuth);

        // Configure the Request
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        };

        if (authObj) {
            requestOptions.headers["Authorization"] = `Bearer ${authObj.authToken}`;
        }

        try {
            const response = await fetch(`${apiRoot}/api/${endpoint}`, requestOptions);

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    // ********************************************
    // Function POST API Data
    // ********************************************   
    static async postApiDataAsync(endpoint, payload, token, isFormData, isMultiPart, requireAuth) {

        var authObj = this.getAuth(requireAuth);

        // Configure the Request
        const requestOptions = {
            method: "POST",
            headers: {
            }
        };

        if (authObj) {
            requestOptions.headers["Authorization"] = `Bearer ${authObj.authToken}`;
        }

        if (token) {
            requestOptions.headers["Verification-Key"] = token;
        }

        if (payload && isFormData) {
            requestOptions.body = payload;
        }
        else if (payload) {
            requestOptions.body = JSON.stringify(payload);
        }

        if (isMultiPart) {
            // This is AutoDetected
            // requestOptions.headers["Content-Type"] = "multipart/form-data";
        }
        else {
            requestOptions.headers["Content-Type"] = "application/json";
        }

        try {
            const response = await fetch(`${apiRoot}/api/${endpoint}`, requestOptions);

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    // ********************************************
    // Function Get Auth Object
    // ********************************************        
    static getAuth(requireAuth) {
        var authToken = localStorage.getItem("mpp-widgets_AuthToken");
        var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

        // Check for Auth Requirement      
        if (!authToken || !authExpiration || authToken == "null") {
            if (requireAuth) {
                throw new Error("401 Authorization error");
            }

            return null;
        }
        else {
            var authObj = {};
            authObj.authToken = authToken;
            authObj.authExpiration = authExpiration;

            return authObj;
        }
    }
}